import React, {useEffect} from 'react'
import * as Chakra from '@chakra-ui/react'
import Seo from '../components/seo'
import {navigate} from "gatsby";

const NotFoundPage = props => {

    useEffect(() => {

        if (typeof window !== "undefined") {
            navigate("/")
        }

    }, [])

  return (
    <Chakra.Box>
      <Seo title={'404'} />
    </Chakra.Box>
  )
}

export default NotFoundPage
